.LandingPage {
  background-color: #C7D7B3;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.Logo {
  height: 25vmin;
  margin-top: 4%;
  border: 2px solid #2A2448;
}

.Description {
  width: 100vh;
  font-size: 19px;
  line-height: 1.5;
  color: #2A2448;
  font-weight: 500;
  align-self: center;
  justify-self: center;
  font-family: "Cormorant Garamond", serif;
}

.DescItem {
  margin-bottom: 40px;
}

.Footer {
  font-size: 16px;
  line-height: 1.5;
  color: #2A2448;
  font-weight: 1000;
  margin-bottom: 40px;
  font-family: 'Roboto', sans-serif;
}
